<template>
  <div class="card-price">
    <div class="heading d-flex justify-content-center align-content-center">
      <span style="white-space: nowrap;">{{ price.package }}</span>
    </div>
    <div class="detail d-flex flex-column justify-content-center align-items-center">
      <h4 class="text-darklight mb-0 text-center">
        {{ price.price | toCurrencyNumber }}
      </h4>
      <span>{{ price.discount }}</span>
    </div>
  </div>
</template>
<script>
export default {
  props: ['price']
}
</script>
<style lang="scss" scoped>
@media (max-width: 767px) {
  h4 {
    font-size: 1.25rem !important;
  }
}
.card-price {
  width: 100%;
  border-radius: 12px;
  box-shadow: 0 8px 12px rgba(12, 20, 61, 0.1);
  @media (min-width: 768px) {
    min-height: 130px;
  }
}
.heading {
  border: 2px solid #005dff;
  background-color: #005dff;
  color: #fff;
  font-size: 1.25em;
  padding: 0.5rem;
  border-radius: 12px 12px 0px 0px;
}
.detail {
  border: 2px solid #005dff;
  padding: 1rem 0;
  border-top: none;
  border-radius: 0px 0px 12px 12px;
  @media (min-width: 768px) {
    height: 84px;
    padding: 1rem;
  }
}
</style>
