<template>
  <b-container class="container-content">
    <div class="page-header">
      <b-row>
        <b-col cols="12" lg="6" xl>
          <h4 class="page-header-text mb-2">{{ $t('nav.pricing') }}</h4>
        </b-col>
        <b-col cols="12" lg="6" xl="4">
          <v-select
            :labelTitle="$t('form_label.nothing_selected')"
            :options="proviceOption"
            v-model="selected"
            class="custom-v-select"
          />
        </b-col>
      </b-row>
    </div>
    <b-row class="justify-content-center">
      <!-- One Time Pricing -->
      <b-col xl="11">
        <div class="heading-content">
          <b-row>
            <b-col class="mb-3"
              ><div>
                <h3 class="text-success">
                  {{ $t('pages.pricing.one_time_pricing') }}
                </h3>
                <div class="plan-description">
                  <div class="text-black d-none d-md-flex flex-column">
                    <span
                      >{{ $t('pages.pricing.one_time_desc_1') }}

                      <br
                    /></span>
                    <span
                      >{{ $t('pages.pricing.one_time_desc_2') }}
                      <br />
                    </span>
                  </div>

                  <span class="d-block d-md-none text-black">
                    {{ $t('pages.pricing.one_time_desc_1') }}
                    {{ $t('pages.pricing.one_time_desc_2') }}
                    <br />
                  </span>

                  <span class="d-block d-md-none text-black"> </span>
                </div></div
            ></b-col>
            <b-col sm="4">
              <div class="d-flex justify-content-end">
                <b-button
                  variant="success"
                  style="width: 258px"
                  class="d-none d-md-block"
                  :to="{ name: 'BookingOneTime' }"
                >
                  <b-img
                    alt="one-time-icon"
                    :src="require('../assets/images/icons/one-time-icon.svg')"
                    class="mr-2"
                  />{{ $t('commons.book_one_time') }}
                </b-button>
                <b-button variant="success" class="d-block d-md-none" block :to="{ name: 'BookingOneTime' }">
                  <b-img
                    alt="one-time-icon"
                    :src="require('../assets/images/icons/one-time-icon.svg')"
                    class="mr-2"
                  />{{ $t('commons.book_one_time') }}
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="price-items-container">
          <b-row align-h="center">
            <b-col
              lg="3"
              md="4"
              cols="6"
              class="d-flex justify-content-center my-2"
              v-for="(price, index) in oneTimePriceList"
              :key="index"
            >
              <PriceItem :price="price" />
            </b-col>
          </b-row>
        </div>
      </b-col>
      <b-col cols="12">
        <hr class="mb-4" />
      </b-col>
      <!-- Multi Package Pricing-->
      <b-col xl="11">
        <div class="heading-content">
          <b-row>
            <b-col class="mb-3">
              <div>
                <h3 class="text-warning">
                  {{ $t('pages.pricing.multi_package_pricing') }}
                </h3>
                <div class="plan-description">
                  <div class="text-black d-none d-md-flex flex-column">
                    <span>
                      {{ $t('pages.pricing.multi_package_desc_1') }}
                      {{ $t('pages.pricing.multi_package_desc_2') }}
                    </span>
                  </div>

                  <span class="d-block d-md-none text-black">
                    {{ $t('pages.pricing.multi_package_desc_1') }}
                    {{ $t('pages.pricing.multi_package_desc_2') }}
                    <br />
                  </span>
                </div>
              </div>
            </b-col>
            <b-col sm="4">
              <div class="d-flex justify-content-end">
                <b-button
                  variant="warning"
                  style="width: 258px"
                  class="d-none d-md-block"
                  :to="{ name: 'BookingMultiPackage' }"
                >
                  <div class="d-flex justify-content-center">
                    <b-img
                      alt="multi-package-icon"
                      :src="require('../assets/images/icons/multi-package-white.svg')"
                      class="mr-2"
                    />
                    <span v-html="$t('button.purchase_multi_package')"></span>
                  </div>
                </b-button>
                <b-button
                  variant="warning"
                  class="d-block d-md-none"
                  block
                  :to="{ name: 'BookingMultiPackage' }"
                >
                  <div class="d-flex justify-content-center">
                    <b-img
                      alt="multi-package-icon"
                      :src="require('../assets/images/icons/multi-package-white.svg')"
                      class="mr-2"
                    />
                    <span v-html="$t('button.purchase_multi_package')"></span>
                  </div>
                </b-button>
              </div>
            </b-col>
          </b-row>
        </div>
        <b-tabs pills content-class="mt-2" class="tab-multipackage-pricing my-4">
          <b-tab
            :title="`${key} ${$t('alias.hrs')}`"
            v-for="key in Object.keys(multiPackagePriceList)"
            :key="key"
            :active="key === '2'"
            @click="fetchMultiPackageByHour(key)"
          >
            <div class="pricing-container">
              <b-row align-h="center">
                <b-col
                  lg="3"
                  md="4"
                  cols="6"
                  class="d-flex justify-content-center my-2"
                  v-for="(price, index) in multiPackagePriceList[key]"
                  :key="index"
                >
                  <PriceItem :price="price" />
                </b-col>
              </b-row>
            </div>
          </b-tab>
        </b-tabs>
        <!-- <b-card
          :title="$t('terms_and_condition.heading')"
          title-tag="h6"
          bg-variant="light"
          class="border-0 mb-2"
        >
          <ul class="pl-3">
            <li>
              To purchase a Multi-Package, you must choose one maid, because otherwise the heavy
              discounts will not be possible.
            </li>
            <li>
              It is recommended that, you try out different maids with a One-Time Booking and find a
              maid that you like.
            </li>
            <li>
              If after the purchase of Multi-Package, you would like to change the maid, you can
              change the made up to 3 times in one Multi-Package. (Change must be made before 24hrs
              of an appointment)
            </li>
            <li>
              If maid needs to be changed due to reasons applicable to maid (ex. not working
              anymore, taking a long leave of absence, etc), then those changes won’t be counted.
            </li>
            <li>Credits are not transferable to other users</li>
            <li>
              Multi-Package credits are valid for 90 days after the 1st day of usage. Unused credits
              after the expiration date will be deleted without refunds.
            </li>
            <li>
              Cancellation can be made at any time, with refunds calculated as below.
            </li>
            <li>Cancellation fee Before 24hr of 1st day of usages 500B.</li>
          </ul>
        </b-card> -->
        <b-card bg-variant="light" class="border-0 mb-2">
          <ServicesContent />
        </b-card>
        <b-card bg-variant="light" class="border-0 mb-5">
          <PoliciesContent />
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import PriceItem from '../components/PriceItem'
import ServicesContent from '../components/Modal/ServicesContent'
import PoliciesContent from '../components/Modal/PoliciesContent'
import ApiClient from '../services'
import VSelect from '@alfsnd/vue-bootstrap-select'

// import { createMeta } from '../config/seo'

@Component({
  components: { PriceItem, ServicesContent, PoliciesContent, VSelect },
  // metaInfo() {
  //   return createMeta({
  //     title: this.$i18n.locale === 'th' ? 'ค่าบริการ' : 'Pricing',
  //     lang: this.$i18n.locale,
  //   })
  // },
})
export default class Pricing extends Vue {
  get proviceOption() {
    return [{ value: 'same_price_province', text: this.$t('province.same_price_province') }]
  }

  selected = null
  options = []
  oneTimeTable = []
  multiPackageTable = {
    2: [],
    3: [],
    4: [],
    5: [],
    6: [],
    7: [],
    8: [],
  }

  async mounted() {
    this.selected = { value: 'same_price_province', text: this.$t('province.same_price_province') }
    try {
      const result = await ApiClient.getPricingOneTimeList()
      this.oneTimeTable = result.data
      await this.fetchMultiPackageByHour(2)
    } catch (e) {
      console.log(e.response)
    }
  }

  get oneTimePriceList() {
    return this.oneTimeTable.map((p) => ({
      package: `${p.hours} ${this.t('commons.hours')}`,
      price: `${p.price} ${this.$t('alias.thb')}`,
    }))
  }

  get multiPackagePriceList() {
    const keys = Object.keys(this.multiPackageTable)
    const result = {}
    keys.forEach((key) => {
      result[key] = this.multiPackageTable[key].map((p) => ({
        package: `${p.package} ${this.t('commons.times')}`,
        price: `${p.price} ${this.$t('alias.thb')}`,
        discount: `${this.t('pages.pricing.save')} ${p.discount}%`,
      }))
    })
    return result
  }

  t(key) {
    return this.$t(`${key}`)
  }

  async fetchMultiPackageByHour(hours) {
    if (this.multiPackageTable[hours].length > 0) return
    try {
      const result = await ApiClient.getPricingMultiPackageList({ hours })
      const multiPackagePriceList = result.data
      this.multiPackageTable[hours] = multiPackagePriceList
    } catch (e) {
      console.log(e.response)
    }
  }
}
</script>
<style lang="scss" scoped>
.btn {
  padding: 0.75em;
}
.price-items-container {
  padding: 16px 0;
  @media (min-width: 768px) {
    padding: 24px 42px;
    border-radius: 16px;
  }
}
.heading-content {
  padding: 0 1rem;
  @media (min-width: 768px) {
    padding: 1.5rem 3rem 0 3rem;
  }
}
.plan-description {
  span {
    font-weight: 400;
    font-size: 13px;
    @media (min-width: 768px) {
      font-size: 18px;
    }
  }
}
</style>
